<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    minValue: {
      type: Number,
      default: 0,
      required: false,
    },
    maxValue: {
      type: Number,
      default: 1,
      required: false,
    },
  },
  data() {
    return {
      focus: false
    };
  },
  mounted() {
    window.addEventListener("mouseup", this.onStop, { passive: false });
    window.addEventListener("mousemove", this.onDrag, { passive: false });
    window.addEventListener("touchmove", this.onDrag, { passive: false });
    window.addEventListener("touchend", this.onStop, { passive: false });
  },
  destroyed() {
    window.removeEventListener("mouseup", this.onStop);
    window.removeEventListener("mousemove", this.onDrag);
    window.removeEventListener("touchmove", this.onDrag);
    window.removeEventListener("touchend", this.onStop);
  },
  computed: {
    styleLeft() {
      return (this.value - this.minValue) / (this.maxValue - this.minValue);
    }
  },
  methods: {
    onDrag(e) {
      if (this.focus) {
        const position = e.touches ? e.touches[0].clientX : e.clientX;
        const line = this.$refs.line;

        if (line) {
          const { left, width } = line.getBoundingClientRect();
          this.$emit(
            "change",
            Math.min(this.maxValue, Math.max(this.minValue, (position - left) * (this.maxValue - this.minValue) / width + this.minValue) )
          );
        }
        if (e.preventDefault) {
          e.preventDefault();
        }
      }
    },
    onStop() {
      this.focus = false;
    },
    onStart(e) {
      this.focus = true;
      this.onDrag(e);
    }
  }
};
</script>


<template>
  <div class="twitter-navigation">
    <div class="twitter-navigation__wrapper">
      <div class="twitter-navigation__zoom-icon twitter-navigation__zoom-icon--left">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2V3C12.75 3.41421 12.4142 3.75 12 3.75C11.5858 3.75 11.25 3.41421 11.25 3V2C11.25 1.58579 11.5858 1.25 12 1.25Z"
            fill="#4D4D4D" />
          <path
            d="M12 6.25C8.82436 6.25 6.25 8.82436 6.25 12C6.25 15.1756 8.82436 17.75 12 17.75C15.1756 17.75 17.75 15.1756 17.75 12C17.75 8.82436 15.1756 6.25 12 6.25Z"
            fill="#4D4D4D" />
          <path
            d="M5.45928 4.39862C5.16638 4.10573 4.69151 4.10573 4.39862 4.39862C4.10572 4.69152 4.10572 5.16639 4.39862 5.45929L5.10572 6.16639C5.39862 6.45929 5.87349 6.45929 6.16638 6.16639C6.45928 5.8735 6.45928 5.39862 6.16638 5.10573L5.45928 4.39862Z"
            fill="#4D4D4D" />
          <path
            d="M22.75 12C22.75 12.4142 22.4142 12.75 22 12.75H21C20.5858 12.75 20.25 12.4142 20.25 12C20.25 11.5858 20.5858 11.25 21 11.25H22C22.4142 11.25 22.75 11.5858 22.75 12Z"
            fill="#4D4D4D" />
          <path
            d="M19.6014 5.45928C19.8943 5.16638 19.8943 4.69151 19.6014 4.39862C19.3085 4.10572 18.8336 4.10572 18.5407 4.39862L17.8336 5.10572C17.5407 5.39862 17.5407 5.87349 17.8336 6.16638C18.1265 6.45928 18.6014 6.45928 18.8943 6.16638L19.6014 5.45928Z"
            fill="#4D4D4D" />
          <path
            d="M12 20.25C12.4142 20.25 12.75 20.5858 12.75 21V22C12.75 22.4142 12.4142 22.75 12 22.75C11.5858 22.75 11.25 22.4142 11.25 22V21C11.25 20.5858 11.5858 20.25 12 20.25Z"
            fill="#4D4D4D" />
          <path
            d="M18.8943 17.8336C18.6014 17.5407 18.1266 17.5407 17.8337 17.8336C17.5408 18.1265 17.5408 18.6014 17.8337 18.8943L18.5408 19.6014C18.8337 19.8943 19.3085 19.8943 19.6014 19.6014C19.8943 19.3085 19.8943 18.8336 19.6014 18.5407L18.8943 17.8336Z"
            fill="#4D4D4D" />
          <path
            d="M3.75 12C3.75 12.4142 3.41421 12.75 3 12.75H2C1.58579 12.75 1.25 12.4142 1.25 12C1.25 11.5858 1.58579 11.25 2 11.25H3C3.41421 11.25 3.75 11.5858 3.75 12Z"
            fill="#4D4D4D" />
          <path
            d="M6.16632 18.8943C6.45921 18.6014 6.45921 18.1265 6.16632 17.8336C5.87342 17.5407 5.39855 17.5407 5.10566 17.8336L4.39855 18.5407C4.10566 18.8336 4.10566 19.3085 4.39855 19.6014C4.69144 19.8943 5.16632 19.8943 5.45921 19.6014L6.16632 18.8943Z"
            fill="#4D4D4D" />
        </svg>
      </div>
      <div class="twitter-navigation__line-wrapper" ref="line" @mousedown="onStart" @touchstart="onStart">
        <div class="twitter-navigation__line">
          <div class="twitter-navigation__fill" :style="{
            flexGrow: styleLeft
          }"></div>
          <div class="twitter-navigation__circle" :class="{ 'twitter-navigation__circle--focus': focus }" :style="{
            left: `${styleLeft * 100}%`
          }">
            <div class="twitter-navigation__inner-circle" :class="{ 'twitter-navigation__inner-circle--focus': focus }">
            </div>
          </div>
        </div>
      </div>

      <div class="twitter-navigation__zoom-icon twitter-navigation__zoom-icon--right">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2V3C12.75 3.41421 12.4142 3.75 12 3.75C11.5858 3.75 11.25 3.41421 11.25 3V2C11.25 1.58579 11.5858 1.25 12 1.25Z"
            fill="#4D4D4D" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M6.25 12C6.25 8.82436 8.82436 6.25 12 6.25C15.1756 6.25 17.75 8.82436 17.75 12C17.75 15.1756 15.1756 17.75 12 17.75C8.82436 17.75 6.25 15.1756 6.25 12ZM12 7.75C9.65279 7.75 7.75 9.65279 7.75 12C7.75 14.3472 9.65279 16.25 12 16.25C14.3472 16.25 16.25 14.3472 16.25 12C16.25 9.65279 14.3472 7.75 12 7.75Z"
            fill="#4D4D4D" />
          <path
            d="M5.45928 4.39862C5.16638 4.10573 4.69151 4.10573 4.39862 4.39862C4.10572 4.69152 4.10572 5.16639 4.39862 5.45929L5.10572 6.16639C5.39862 6.45929 5.87349 6.45929 6.16638 6.16639C6.45928 5.8735 6.45928 5.39862 6.16638 5.10573L5.45928 4.39862Z"
            fill="#4D4D4D" />
          <path
            d="M22.75 12C22.75 12.4142 22.4142 12.75 22 12.75H21C20.5858 12.75 20.25 12.4142 20.25 12C20.25 11.5858 20.5858 11.25 21 11.25H22C22.4142 11.25 22.75 11.5858 22.75 12Z"
            fill="#4D4D4D" />
          <path
            d="M19.6014 5.45928C19.8943 5.16638 19.8943 4.69151 19.6014 4.39862C19.3085 4.10572 18.8336 4.10572 18.5407 4.39862L17.8336 5.10572C17.5407 5.39862 17.5407 5.87349 17.8336 6.16638C18.1265 6.45928 18.6014 6.45928 18.8943 6.16638L19.6014 5.45928Z"
            fill="#4D4D4D" />
          <path
            d="M12 20.25C12.4142 20.25 12.75 20.5858 12.75 21V22C12.75 22.4142 12.4142 22.75 12 22.75C11.5858 22.75 11.25 22.4142 11.25 22V21C11.25 20.5858 11.5858 20.25 12 20.25Z"
            fill="#4D4D4D" />
          <path
            d="M18.8943 17.8336C18.6014 17.5407 18.1266 17.5407 17.8337 17.8336C17.5408 18.1265 17.5408 18.6014 17.8337 18.8943L18.5408 19.6014C18.8337 19.8943 19.3085 19.8943 19.6014 19.6014C19.8943 19.3085 19.8943 18.8336 19.6014 18.5407L18.8943 17.8336Z"
            fill="#4D4D4D" />
          <path
            d="M3.75 12C3.75 12.4142 3.41421 12.75 3 12.75H2C1.58579 12.75 1.25 12.4142 1.25 12C1.25 11.5858 1.58579 11.25 2 11.25H3C3.41421 11.25 3.75 11.5858 3.75 12Z"
            fill="#4D4D4D" />
          <path
            d="M6.16632 18.8943C6.45921 18.6014 6.45921 18.1265 6.16632 17.8336C5.87342 17.5407 5.39855 17.5407 5.10566 17.8336L4.39855 18.5407C4.10566 18.8336 4.10566 19.3085 4.39855 19.6014C4.69144 19.8943 5.16632 19.8943 5.45921 19.6014L6.16632 18.8943Z"
            fill="#4D4D4D" />
        </svg>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.twitter-navigation {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 50px;

  &__wrapper {
    display: flex;
    align-items: center;
    max-width: 400px;
    width: 100%;
  }

  &__zoom-icon {
    height: 1.5rem;
    width: 1.5rem;
    // fill: rgb(101, 119, 134);
    flex-shrink: 0;

    &--left {
      margin-right: 10px;
    }

    &--right {
      margin-left: 10px;
    }
  }

  &__line-wrapper {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
  }

  &__line {
    background: #8D8D8D;
    height: 5px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    position: relative;
    align-items: center;
  }

  &__fill {
    background: rgb(29, 161, 242);
    // background: #4D4D4D;
    align-self: stretch;
    flex-basis: auto;
    flex-direction: column;
    flex-shrink: 0;
  }

  &__circle {
    width: 30px;
    height: 30px;
    margin-left: -15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    transition-duration: 0.2s;
    transition-property: background-color, box-shadow;
    background-color: transparent;

    &:hover {
      background-color: rgba(29, 161, 242, 0.1);
    }

    &--focus {
      background-color: rgba(29, 161, 242, 0.2);
    }
  }

  &__inner-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    // background-color: rgb(29, 161, 242);
    background-color: #4D4D4D;
    transform: scale(1);
    transition-duration: 0.1s;
    transition-property: transform;
    box-shadow: rgba(101, 119, 134, 0.2) 0px 0px 7px,
      rgba(101, 119, 134, 0.15) 0px 1px 3px 1px;

    &--focus {
      transform: scale(1.2);
    }
  }
}</style>
