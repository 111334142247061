<template>
  <div class="crop-image">

    <div class="top-div">
      <div class="text-right d-none">
        <button class="reload-btn" @click="reuploadClicked">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2.16312 5.33332C0.968461 5.33332 0 6.30178 0 7.49644V13.3333C0 16.1617 0 17.576 0.87868 18.4546C1.75736 19.3333 3.17157 19.3333 6 19.3333H15C17.8284 19.3333 19.2426 19.3333 20.1213 18.4546C21 17.576 21 16.1617 21 13.3333V7.49644C21 6.30178 20.0315 5.33332 18.8369 5.33332C18.0176 5.33332 17.2685 4.87041 16.9021 4.13758L15.9444 2.22221L15.9444 2.2222C15.727 1.7873 15.6183 1.56985 15.4744 1.39519C15.1936 1.05427 14.8079 0.815878 14.3774 0.717208C14.1569 0.666656 13.9137 0.666656 13.4275 0.666656H7.5725C7.08625 0.666656 6.84313 0.666656 6.62258 0.717208C6.19209 0.815878 5.80636 1.05427 5.52559 1.39519C5.38174 1.56985 5.27301 1.7873 5.05556 2.2222L5.05556 2.22221L4.09787 4.13758C3.73146 4.87041 2.98245 5.33332 2.16312 5.33332ZM13.1663 11.1668C13.1663 12.6396 11.9724 13.8335 10.4997 13.8335C9.02691 13.8335 7.83301 12.6396 7.83301 11.1668C7.83301 9.69406 9.02691 8.50015 10.4997 8.50015C11.9724 8.50015 13.1663 9.69406 13.1663 11.1668ZM15.1663 11.1668C15.1663 13.7441 13.077 15.8335 10.4997 15.8335C7.92235 15.8335 5.83301 13.7441 5.83301 11.1668C5.83301 8.58949 7.92235 6.50015 10.4997 6.50015C13.077 6.50015 15.1663 8.58949 15.1663 11.1668Z"
              fill="#4D4D4D" />
          </svg>
          <p>重新上傳</p>
        </button>
        <input type="file" ref="file" @change="loadImage($event)" accept="image/*" class="d-none">
      </div>

      <div class="square">
        <div class="square-inner">
          <ImageAdjust ref="cropper" :src="image.src" :canMove="mode === 'crop'" :brightness="bright" :restrict="imageRestrict"></ImageAdjust>
        </div>
      </div>

    </div>

    <div class="bottom-div">

      <div class="tools">

        <div class="tools-row" v-if="mode === 'main'">
          <div class="tools-col">
            <button class="tools-btn" @click="changeMode('crop')">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path
                  d="M14.7734 3L16.1371 3C18.3964 3 20.228 4.83156 20.228 7.09091L20.228 9.81818M20.228 9.81818L22.2734 7.77273M20.228 9.81818L18.1825 7.77273"
                  stroke="#4D4D4D" stroke-width="1.36364" stroke-linecap="round" stroke-linejoin="round" />
                <rect x="16.1367" y="7.09142" width="10.9091" height="10.9091" rx="1.36364"
                  transform="rotate(90 16.1367 7.09142)" stroke="#4D4D4D" stroke-width="1.36364" />
                <path d="M8 13V10M8 10H11M8 10L11 13" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path d="M13 12V15M13 15H10M13 15L9 11" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <p>縮放及旋轉</p>
            </button>
          </div>

          <div class="tools-col">
            <button class="tools-btn" @click="changeMode('brightness')">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 19 18" fill="none">
                <g clip-path="url(#clip0_4007_29856)">
                  <path
                    d="M9.375 2.67188C5.88562 2.67188 3.04688 5.51062 3.04688 9C3.04688 12.4894 5.88562 15.3281 9.375 15.3281C12.8644 15.3281 15.7031 12.4894 15.7031 9C15.7031 5.51062 12.8644 2.67188 9.375 2.67188ZM10.0781 4.12875C11.1361 4.28063 12.0862 4.77 12.8156 5.48438H10.0781V4.12875ZM10.0781 12.5156H12.8156C12.0862 13.23 11.1356 13.7194 10.0781 13.8713V12.5156ZM10.0781 11.1094V9.70312H14.2463C14.175 10.1995 14.0297 10.672 13.8211 11.1094H10.0781ZM10.0781 8.29688V6.89062H13.8211C14.0297 7.32797 14.175 7.80047 14.2463 8.29688H10.0777H10.0781ZM4.45312 9C4.45312 6.52453 6.28969 4.47047 8.67188 4.12875V13.8713C6.28969 13.5291 4.45312 11.475 4.45312 9Z"
                    fill="#4D4D4D" />
                  <path
                    d="M1.78125 9C1.78125 9.38812 1.46672 9.70312 1.07812 9.70312C0.689531 9.70312 0.375 9.38859 0.375 9C0.375 8.61141 0.689531 8.29688 1.07812 8.29688C1.46672 8.29688 1.78125 8.61141 1.78125 9Z"
                    fill="#4D4D4D" />
                  <path
                    d="M18.375 9C18.375 9.38812 18.06 9.70312 17.6719 9.70312C17.2837 9.70312 16.9688 9.38859 16.9688 9C16.9688 8.61141 17.2833 8.29688 17.6719 8.29688C18.0605 8.29688 18.375 8.61141 18.375 9Z"
                    fill="#4D4D4D" />
                  <path
                    d="M2.91109 14.4691C2.63641 14.7438 2.63641 15.1891 2.91109 15.4633C3.18578 15.738 3.63109 15.738 3.90578 15.4633C4.18047 15.1886 4.18047 14.7433 3.90578 14.4691C3.63109 14.1944 3.18578 14.1944 2.91109 14.4691Z"
                    fill="#4D4D4D" />
                  <path
                    d="M14.8447 2.53641C14.57 2.8111 14.57 3.25641 14.8447 3.53063C15.1194 3.80532 15.5642 3.80532 15.8389 3.53063C16.1136 3.25594 16.1136 2.81063 15.8389 2.53641C15.5642 2.26172 15.1189 2.26172 14.8447 2.53641Z"
                    fill="#4D4D4D" />
                  <path
                    d="M10.0781 17.2969C10.0781 17.6855 9.76359 18 9.375 18C8.98641 18 8.67188 17.685 8.67188 17.2969C8.67188 16.9087 8.98641 16.5938 9.375 16.5938C9.76359 16.5938 10.0781 16.9083 10.0781 17.2969Z"
                    fill="#4D4D4D" />
                  <path
                    d="M10.0781 0.703125C10.0781 1.09172 9.76359 1.40625 9.375 1.40625C8.98641 1.40625 8.67188 1.09172 8.67188 0.703125C8.67188 0.314531 8.98641 0 9.375 0C9.76359 0 10.0781 0.314531 10.0781 0.703125Z"
                    fill="#4D4D4D" />
                  <path
                    d="M14.8447 14.4691C14.57 14.7438 14.57 15.1891 14.8447 15.4633C15.1194 15.738 15.5642 15.738 15.8389 15.4633C16.1136 15.1886 16.1136 14.7433 15.8389 14.4691C15.5642 14.1944 15.1189 14.1944 14.8447 14.4691Z"
                    fill="#4D4D4D" />
                  <path
                    d="M3.90578 3.53089C4.18047 3.2562 4.18047 2.81089 3.90578 2.53667C3.63109 2.26198 3.18578 2.26198 2.91109 2.53667C2.63641 2.81136 2.63641 3.25667 2.91109 3.53089C3.18578 3.80558 3.63109 3.80558 3.90578 3.53089Z"
                    fill="#4D4D4D" />
                </g>
                <defs>
                  <clipPath id="clip0_4007_29856">
                    <rect width="18" height="18" fill="white" transform="translate(0.375)" />
                  </clipPath>
                </defs>
              </svg>
              <p>曝光</p>
            </button>
          </div>

        </div>

        <div v-if="mode === 'crop'">
          <div class="text-center">
            <input type="checkbox" v-model="imageRestrict" id="restrict-check"/> <label for="restrict-check">圖片滿版</label>
          </div>
          
          <div class="tools-row">
            <div class="tools-col">
              <button class="tools-btn" @click="rotateLeft">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                  <path
                    d="M10.4766 3L9.11293 3C6.85358 3 5.02202 4.83156 5.02202 7.09091L5.02202 9.81818M5.02202 9.81818L2.97656 7.77273M5.02202 9.81818L7.06747 7.77273"
                    stroke="#212429" stroke-width="1.36364" stroke-linecap="round" stroke-linejoin="round" />
                  <rect width="10.9091" height="10.9091" rx="1.36364"
                    transform="matrix(-4.37114e-08 1 1 4.37114e-08 9.11328 7.09143)" stroke="#212429"
                    stroke-width="1.36364" />
                </svg>
                <p>逆時鐘90度</p>
              </button>
            </div>
  
            <div class="tools-col">
              <button class="tools-btn" @click="rotateRight">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path
                    d="M15.0234 3L16.3871 3C18.6464 3 20.478 4.83156 20.478 7.09091L20.478 9.81818M20.478 9.81818L22.5234 7.77273M20.478 9.81818L18.4325 7.77273"
                    stroke="#212429" stroke-width="1.36364" stroke-linecap="round" stroke-linejoin="round" />
                  <rect x="16.3867" y="7.09143" width="10.9091" height="10.9091" rx="1.36364"
                    transform="rotate(90 16.3867 7.09143)" stroke="#212429" stroke-width="1.36364" />
                </svg>
                <p>順時鐘90度</p>
              </button>
            </div>
          </div>
        </div>

        <div class="p-3" v-if="mode === 'brightness'">
          <BrightnessNavigation :value="bright" :maxValue="1.5" :minValue="0.5" @change="bright = $event">
          </BrightnessNavigation>
        </div>
      </div>

      <div class="footer-div">
        <div class="footer-div-col" v-if="mode === 'main'">
          <button class="orange-btn" @click="crop">上傳</button>
        </div>
        <div class="footer-div-col d-none">
          <button class="bw-btn">取消</button>
        </div>
        <div class="footer-div-col" v-if="mode !== 'main'">
          <button class="bw-btn" @click="changeMode('main')">確認</button>
        </div>
      </div>
    </div>




  </div>
</template>

<script>

function getMimeType(file, fallback = null) {
  const byteArray = (new Uint8Array(file)).subarray(0, 4);
  let header = '';
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
}

// @ is an alias to /src
// import 'vue-advanced-cropper/dist/style.css';

import ImageAdjust from '@/components/cropImage/ImageAdjust.vue';
import BrightnessNavigation from '@/components/cropImage/BrightnessNavigation.vue';

export default {
  name: 'CropImage',
  data() {
    return {
      mode: 'main',
      imgData: '',
      bright: 1,
      imgRotate: 0,
      zoom: 0,
      imageRestrict: true,

      image: {
        src: null,
        type: null
      },
    };
  },
  props: {
    defaultImage: {
      type: Object,
      default: undefined,
      required: false,
    }
  },
  components: {
    ImageAdjust,
    BrightnessNavigation,
  },
  computed: {
    winWidth() {
      return window.innerWidth;
    },
    cropSize() {
      return this.winWidth * 0.9;
    },
  },
  watch: {
  },
  created() {
    if (this.defaultImage) {
      this.image = Object.assign({}, this.image, this.defaultImage);
    }
  },
  async mounted() {

  },
  methods: {
    reuploadClicked() {
      this.$refs.file.click();
    },
    loadImage(event) {
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src)
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. The steps below are designated to determine a file mime type to use it during the 
        // getting of a cropped image from the canvas. You can replace it them by the following string, 
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getMimeType(e.target.result, files[0].type),
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    },

    async changeMode(mode) {
      this.mode = mode;
    },
    rotateRight() {
      const cropper = this.$refs.cropper;
      cropper.rotateRight();
    },
    rotateLeft() {
      const cropper = this.$refs.cropper;
      cropper.rotateLeft();
    },
    async crop() {
      try {
        const dataUrl = await this.$refs.cropper.crop();
        this.$emit('cropped', dataUrl);
      } catch (error) {
        this.$emit('error', error);
      }
      // console.log(dataUrl);
    }
  }
}
</script>

<style lang="scss" scoped>
.crop-image {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top-div {}

  .bottom-div {
    padding-bottom: 1.5rem;
  }

  .reload-btn {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    column-gap: .25rem;
    padding: .5rem 1rem;

    p {
      margin: 0;
    }
  }

  .square {
    position: relative;
    width: 100%;
    padding-top: 100%;

    // width: 100px;
    // height: 100px;
    .square-inner {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .tools-row {
    display: flex;
    flex-direction: row;

    .tools-col {
      flex: 1 1;

      .tools-btn {
        width: 100%;
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        row-gap: .1rem;
        padding: .6em;
        align-items: center;
        color: #000;

        svg {}

        p {
          margin: 0;
        }
      }
    }
  }

  .footer-div {
    display: flex;
    column-gap: 1rem;
    padding: .5rem 1rem;

    .footer-div-col {
      flex: 1 1;

      button {
        width: 100%;
        padding: .5rem;
        background-color: transparent;
        border: solid 1px #000;
        border-radius: .75rem;
        color: #000;

        &.orange-btn {
          border-color: #E3861A;
          background-color: #E3861A;
          color: #fff;
        }

        &.bw-btn {}
      }
    }
  }
}



.twitter-cropper {
  height: 521px;

  &__background {
    background-color: #edf2f4;
  }

  &__foreground {
    background-color: #edf2f4;
  }

  &__stencil {
    border: solid 5px rgb(29, 161, 242);
  }
}</style>
