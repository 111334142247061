import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/share/martketplace/service',
    meta: {
      navCategory: 'home',
    },
    component: Home
  },
  // {
  //   path: '/closet',
  //   name: 'Closet',
  //   meta: {
  //     navCategory: 'closet',
  //   },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "closet" */ '../views/Closet.vue')
  // },
  {
    path: '/calendar',
    name: 'OutfitCalendar',
    meta: {
      navCategory: 'closet',
      inner: true,
      backName: 'Closet',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "closet" */ '../views/closet/OutfitCalendar.vue')
  },
  {
    path: '/share',
    name: 'Share',
    redirect: 'share/closet',
    meta: {
      navCategory: 'share',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "share" */ '../views/Share.vue'),
    children: [
      {
        path: 'outfit',
        name: 'ShareOutfit',
        component: () => import(/* webpackChunkName: "share-outfit" */ '../views/share/FriendOutfits.vue'),
        meta: {
          navCategory: 'share',
        },
      },
      {
        path: 'closet',
        name: 'ShareCloths',
        component: () => import(/* webpackChunkName: "share-cloth" */ '../views/share/FriendCloths.vue'),
        meta: {
          navCategory: 'share',
        },
      },
      {
        path: 'martketplace',
        name: 'ShareMarketPlace',
        redirect: 'martketplace/service',
        component: () => import(/* webpackChunkName: "share-market-place" */ '../views/share/MarketPlace.vue'),
        meta: {
          navCategory: 'share',
        },
        children: [
          {
            path: 'service',
            name: 'MarketPlaceService',
            component: () => import(/* webpackChunkName: "market-place-service" */ '../views/share/MarketPlaceService.vue'),
            meta: {
              navCategory: 'share',
            },
          },
          {
            path: 'history',
            name: 'MarketPlaceHistory',
            component: () => import(/* webpackChunkName: "market-place-history" */ '../views/share/MarketPlaceHistory.vue'),
            meta: {
              navCategory: 'share',
            },
          },
          {
            path: 'service',
            name: 'MarketPlaceRewards',
            component: () => import(/* webpackChunkName: "market-place-rewards" */ '../views/share/MarketPlaceRewards.vue'),
            meta: {
              navCategory: 'share',
            },
          },
        ],
      },
      {
        path: 'recycle',
        name: 'ShareRecycleMap',
        component: () => import(/* webpackChunkName: "share-recycle-map" */ '../views/share/ShareRecycleMap.vue'),
        meta: {
          navCategory: 'share',
        },
      },
    ],
  },
  {
    path: '/user/:serial',
    name: 'UserPublic',
    component: () => import(/* webpackChunkName: "user-public" */ '../views/share/UserPublic.vue'),
    meta: {
      navCategory: 'share',
      inner: true,
      special: 'green',
    },
  },
  {
    path: '/analyze',
    name: 'Analyze',
    meta: {
      navCategory: 'analyze',
    },
    component: () => import(/* webpackChunkName: "closet-content" */ '../views/Analyze.vue'),
    children: [
      {
        path: 'body',
        name: 'AnalyzeBody',
        component: () => import(/* webpackChunkName: "closet-recycle" */ '../views/analyze/AnalyzeBody.vue'),
        meta: {
          navCategory: 'analyze',
        },
      },
      {
        path: 'closet',
        name: 'AnalyzeCloset',
        component: () => import(/* webpackChunkName: "closet-analytics" */ '../views/analyze/AnalyzeCloset.vue'),
        meta: {
          navCategory: 'analyze',
        },
      },
    ],
  },
  {
    path: '/analyzeHada',
    name: 'AnalyzeHada',
    meta: {
      navCategory: 'analyze',
    },
    component: () => import(/* webpackChunkName: "analyze-hada" */ '../views/analyze/AnalyzeHada.vue'),
  },
  {
    path: '/cloths',
    name: 'Closet',
    meta: {
      navCategory: 'closet',
    },
    component: () => import(/* webpackChunkName: "closet-content" */ '../views/closet/ClosetContent.vue'),
    children: [
      {
        path: 'recycle',
        name: 'ClosetContentRecycle',
        component: () => import(/* webpackChunkName: "closet-recycle" */ '../views/closet/RecycleList.vue'),
        meta: {
          navCategory: 'closet',
          inner: true,
        },
      },
      {
        path: 'analytics',
        name: 'ClosetContentAnalytics',
        component: () => import(/* webpackChunkName: "closet-analytics" */ '../views/closet/ClosetData.vue'),
        meta: {
          navCategory: 'closet',
          inner: true,
        },
      },
      {
        path: 'snippest',
        name: 'ClosetContentSnippest',
        component: () => import(/* webpackChunkName: "closet-snippest" */ '../views/closet/ClosetDataSimple.vue'),
        meta: {
          navCategory: 'closet',
          inner: true,
        },
      },
      // {
      //   path: 'list',
      //   name: 'ClosetContentListAll',
      //   component: () => import(/* webpackChunkName: "closet-list" */ '../views/closet/ClothList.vue'),
      //   meta: {
      //     navCategory: 'closet',
      //     inner: true,
      //   },
      // },
      {
        path: 'list',
        name: 'ClosetContentListAll',
        component: () => import(/* webpackChunkName: "closet-list" */ '../views/closet/ClosetBrowser.vue'),
        meta: {
          navCategory: 'closet',
          inner: true,
        },
      },
      {
        path: 'list/:filterCategory1',
        name: 'ClosetContentList',
        component: () => import(/* webpackChunkName: "closet-list" */ '../views/closet/ClothList.vue'),
        meta: {
          navCategory: 'closet',
          inner: true,
        },
      },
      {
        path: 'public',
        name: 'ClosetContentPublicList',
        component: () => import(/* webpackChunkName: "closet-list" */ '../views/closet/PublicList.vue'),
        meta: {
          navCategory: 'closet',
          inner: true,
        },
      },
      {
        path: 'outfits',
        name: 'ClosetContentOutfitList',
        component: () => import(/* webpackChunkName: "closet-outfits" */ '../views/closet/OutfitList.vue'),
        meta: {
          navCategory: 'closet',
          inner: true,
        },
      },
      {
        path: '/',
        redirect: () => {
          // 方法接收目标路由作为参数
          // return 重定向的字符串路径/路径对象
          return { name: 'ClosetContentListAll' }
        },
      }
    ],
  },
  {
    path: '/topic/:id',
    name: 'TopicContent',
    meta: {
      navCategory: 'topic',
    },
    component: () => import(/* webpackChunkName: "topic-content" */ '../views/topic/TopicContent.vue'),
  },
  {
    path: '/topic',
    name: 'Topic',
    meta: {
      navCategory: 'topic',
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "topic" */ '../views/Topic.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      publicPage: true,
      navCategory: '',
      hideTopNav: true,
      hideBottomNav: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue')
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    meta: {
      publicPage: true,
      navCategory: '',
      hideTopNav: true,
      hideBottomNav: true,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/login/ResetPassword.vue')
  },
  {
    path: '/delivery',
    name: 'Delivery',
    meta: {
      navCategory: 'delivery',
    },
    component: () => import(/* webpackChunkName: "delivery-index" */ '../views/delivery/DeliveryIndex.vue'),
    children: [
      {
        path: 'shop',
        name: 'DeliveryShop',
        component: () => import(/* webpackChunkName: "delivery-shop" */ '../views/delivery/DeliveryShop.vue'),
        meta: {
          navCategory: 'delivery',
        },
      },
      {
        path: 'list',
        name: 'DeliveryItemList',
        component: () => import(/* webpackChunkName: "delivery-item-list" */ '../views/delivery/DeliveryItemList.vue'),
        meta: {
          navCategory: 'delivery',
        },
      },
      {
        path: '',
        redirect: 'shop',
      },
    ],
  },
  {
    path: '/shopDone',
    name: 'DeliveryPayResult',
    component: () => import(/* webpackChunkName: "delivery-pay-result" */ '../views/delivery/DeliveryPayResult.vue'),
    meta: {
      navCategory: 'delivery',
    },
  },
  {
    path: '/epc',
    name: 'EpcTest',
    meta: {
      navCategory: 'closet',
    },
    component: () => import(/* webpackChunkName: "epc-index" */ '../views/epc/EpcIndex.vue'),
  },
  {
    path: '/review',
    name: 'ReviewShopList',
    meta: {
      publicPage: true,
      navCategory: '',
      hideTopNav: true,
      hideBottomNav: true,
    },
    component: () => import(/* webpackChunkName: "ReviewShopList" */ '../views/clothReviewing/ReviewShopList.vue'),
  },
  {
    path: '/recycleBox',
    name: 'RecycleTest',
    meta: {
      publicPage: true,
      navCategory: '',
      hideTopNav: true,
      hideBottomNav: true,
    },
    component: () => import(/* webpackChunkName: "RecycleTest" */ '../views/recycle/RecycleTest.vue'),
  },
  {
    path: '/test',
    name: 'Test',
    meta: {
      navCategory: 'closet',
    },
    component: () => import(/* webpackChunkName: "Test" */ '../views/test/Test.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
