<template>
  <div class="wc-modal-bg" @click="backClicked" ref="back">
    <div class="window">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'wc-modal',
  data() {
    return {
    }
  },
  computed: {
    isPositionBottom() {
      return this.position === 'bottom';
    }
  },
  props: {
    position: {
      type: String,
      required: false,
      default: 'center',
    }
  },
  methods: {
    backClicked(evt) {
      if (evt.target === this.$refs.back) {
        this.$emit('backClick');
      }
    }
  }

}
</script>

<style scoped>
  .wc-modal-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #0009;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .window {
    position: relative;
  }
</style>
